<script setup>
import {
  computed, reactive, ref, watch, watchEffect, onMounted, useTemplateRef,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { VueRecaptcha } from 'vue-recaptcha';
import PFContactEmailFormAgreement from '@/components/common/PFContactEmailFormAgreement';
import PFContactEmailFormResponseErrorMessage from '@/components/common/PFContactEmailFormResponseErrorMessage';
import { EMAIL_REGEX, EMBARGO_COUNTRIES } from '@/utils/constants';
import { openInNewTab } from '@/utils/utils.js';
import urlLinks from '@/plugins/linkUrl';
import '@sapudex/web-components/dist/TextField.js';
import '@sapudex/web-components/dist/TextArea.js';
import '@sapudex/web-components/dist/PhoneNumberField.js';
import '@sapudex/web-components/dist/CountrySelector.js';
import '@sapudex/web-components/dist/Checkbox.js';
import '@sapudex/web-components/dist/MessageStrip.js';
import '@sapudex/web-components/dist/SelectBox.js'
import '@sapudex/web-components/dist/SelectBoxItem.js';
import "@sapudex/web-components/dist/MessageStrip.js";

const store = useStore();
const { t } = useI18n();

const MAX_CHAR = 1000;
const SITE_KEY = VUE_APP_RECAPTCHA_PUBLIC_KEY;
const siteKey = SITE_KEY || 'test';
const RELATIONSHIP_TO_SAP_OPTIONS = [
  'custom', 
  'consultant',
  'investor.or.shareholder',
  'partner',
  'press.or.analyst',
  'prospective.customer',
  'prospective.partner',
  'sap.employee',
  'student'
]
const faqLinkImplementationPartner = urlLinks.HELP_TEXT_LEARN_MORE.SAP_IMPLEMENTATION_PARTNER;

const form = reactive({
  fields: {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phone: '',
    country: '',
    relationshipToSap: '',
    comments: '',
  },
  errors: {
    firstName: false,
    lastName: false,
    email: false,
    emailError: false,
    company: false,
    phone: false,
    country: false,
    relationshipToSap: false,
    comments: false,
    emptyCheckbox: false,
    recaptchaError: false,
  },
});
const requestStatus = computed(() => store.state.contactEmail.requestStatus);

const recaptchaResponse = ref(null);
const isButtonDisabled = ref(true);

const errorMessage = t('mandatory.field.error.message');
const invalidEmailMessage = t('local.office.email.error.message');

const firstNameValueState = computed(() => (form.errors.firstName ? 'Error' : 'Standard'));
const firstNameSupportingText = computed(() => (form.errors.firstName ? errorMessage : ''));

const lastNameValueState = computed(() => (form.errors.lastName ? 'Error' : 'Standard'));
const lastNameSupportingText = computed(() => (form.errors.lastName ? errorMessage : ''));

const emailValueState = computed(() => (form.errors.email || form.errors.emailError ? 'Error' : 'Standard'));
const emailSupportingText = computed(() => {
  if (form.errors.email) {
    return errorMessage;
  }
  if (form.errors.emailError) {
    return invalidEmailMessage;
  }
  return '';
});
const companyValueState = computed(() => (form.errors.company ? 'Error' : 'Standard'));
const companySupportingText = computed(() => (form.errors.company ? errorMessage : ''));

const countryValueState  = computed(() => (form.errors.country ? 'Error' : 'Standard'));
const countrySupportingText = computed(() => (form.errors.country ? errorMessage : ''));

const relationshipToSapValueState = computed(() => (form.errors.relationshipToSap ? 'Error' : 'Standard'));
const relationshipToSapSupportingText = computed(() => (form.errors.relationshipToSap ? errorMessage : ''));

const commentsFieldState = computed(() => (form.errors.comments ? 'Error' : 'Standard'));
const commentsSupportingText = computed(() => (form.errors.comments ? errorMessage : ''));

const isContactEmailChecked = computed(() => store.state.contactEmail.contactEmailCheckBox);

function validateField(fieldName) {
  form.errors[fieldName] = !form.fields[fieldName];
}

function onCountrySelect(event) {
  // eslint-disable-next-line
  const selectedCountryCode = event.detail.item._id;
  form.fields.country = selectedCountryCode;
}
function onRelationshipToSapSelect(event) {
  const selectedRelationshipToSap = event.detail.item._state.label;
  form.fields.relationshipToSap = selectedRelationshipToSap;
  validateField('relationshipToSap');
}

function onPhoneEntered(event) {
  // eslint-disable-next-line
  const phoneNumber = event.srcElement._state.value;
  form.fields.phone = phoneNumber;
}

function checkEmailField() {
  if (!form.fields.email || form.fields.email.length === 0) {
    form.errors.email = true;
    form.errors.emailError = false;
  } else if (EMAIL_REGEX.test(form.fields.email)) {
    form.errors.email = false;
    form.errors.emailError = false;
  } else {
    form.errors.email = false;
    form.errors.emailError = true;
  }
}

const isFirstNameInvalid = computed(() => !form.fields.firstName || form.errors.firstName);
const isLastNameInvalid = computed(() => !form.fields.lastName || form.errors.lastName);
const isEmailInvalid = computed(() => !form.fields.email || form.errors.email || form.errors.emailError);
const isCompanyInvalid = computed(() => !form.fields.company || form.errors.company);
const isCommentsInvalid = computed(() => !form.fields.comments || form.errors.comments);
const isRelationshipToSapInvalid = computed(() => !form.fields.relationshipToSap || form.errors.relationshipToSap);
const isCountryInvalid = computed(() => !form.fields.country || form.errors.country);
const isCheckboxInvalid = computed(() => !isContactEmailChecked.value || form.errors.emptyCheckbox);
const isRecaptchaInvalid = computed(() => !recaptchaResponse.value || form.errors.recaptchaError);

const isFormInvalid = computed(
  () => isFirstNameInvalid.value
    || isLastNameInvalid.value
    || isEmailInvalid.value
    || isCompanyInvalid.value
    || isCommentsInvalid.value
    || isRelationshipToSapInvalid.value
    || isCountryInvalid.value
    || isCheckboxInvalid.value
    || isRecaptchaInvalid.value,
);

function syncFormWithVuex() {
  store.dispatch('contactEmail/updateContactEmail', form.fields.email);
  store.dispatch('contactEmail/updateFirstName', form.fields.firstName);
  store.dispatch('contactEmail/updateLastName', form.fields.lastName);
  store.dispatch('contactEmail/updateCompany', form.fields.company);
  store.dispatch('contactEmail/updatePhone', form.fields.phone);
  store.dispatch('contactEmail/updateCountry', form.fields.country);
  store.dispatch('contactEmail/updateRelationshipToSap', form.fields.relationshipToSap);
  store.dispatch('contactEmail/updateComments', form.fields.comments);
}

const recaptchaRef = useTemplateRef('recaptcha');

function onVerify(response) {
  recaptchaResponse.value = response;
  form.errors.recaptchaError = false;
  store.dispatch('contactEmail/updateCaptchaResponseToken', response);
}

function resetRecaptcha() {
  if (recaptchaRef.value) {
    recaptchaRef.value.reset();
  }
  recaptchaResponse.value = null;
  validateField('recaptchaError');
  store.dispatch('contactEmail/updateCaptchaResponseToken', null);
}

function recaptchaFailed() {
  console.log('recaptcha failed');
}

function getErrorFieldsName() {
  return Object.keys(form.errors)
    .filter((field) => form.errors[field])
    .toString();
}

function openLearnMore() {
  const win = window.open(faqLinkImplementationPartner.value, '_blank');
  win.focus();
}

function sendRequest() {
  if (!isFormInvalid.value) {
    syncFormWithVuex();
    store.dispatch('contactEmail/sendData');
    resetRecaptcha();
    isContactEmailChecked.value = false;
  } else {
    this.$eventBus.emit('PFAACustomEvent', {
      eventName: 'ContactFormError',
      custom2: `Contact form validation error in these fields: ${getErrorFieldsName()}`,
      custom3: true,
    });
  }
}

function onInputValidation(event, fieldName) {
  form.fields[fieldName] = event.target.value;
  if (fieldName !== 'email') {
    validateField(fieldName);
  } else {
    checkEmailField(fieldName);
  }
}

watch(
  () => store.state.contactEmail.customerInfo,
  (newVal) => {
    form.fields.email = newVal.email;
    form.fields.firstName = newVal.firstName;
    form.fields.lastName = newVal.lastName;
    form.fields.company = newVal.company;
    form.fields.phone = newVal.phone;
    form.fields.country = newVal.country;
    form.fields.relationshipToSap = newVal.relationshipToSap;
    form.fields.comments = newVal.comments;
  },
  { deep: true, immediate: true },
);

watchEffect(() => {
  isButtonDisabled.value = isFormInvalid.value;
});
</script>

<template>
  <form automation-id="contactEmailFormContent-form">
    <udex-message-strip
        design="Information"
        status-type="Icon"
        hideCloseButton
        class="mb-8"
      >
      <div class="flex items-center justify-between gap-4">
          <span class="body-s-size">{{ t('contact.me.modal.tips') }}</span>
          <udex-button
            class="flex shrink-0 justify-center"
            id="custom-usage-patterns-forms-learn-more"
            accessible-name="Read Tips before Selecting a Partner"
            type="Submit"
            size="Medium"
            @click="openInNewTab(faqLinkImplementationPartner)"
            udex-button
          >
          {{ t("read.tips") }}
        </udex-button>
      </div>
      </udex-message-strip>
    <div
      class="HeadingMediumXXXS-size mb-4"
      automation-id="contactEmailFormContent-personalDetailsTitle"
    >
      {{ t("contact.me.modal.left.title") }}
    </div>
    <div class="flex gap-1.5 flex-col max-[850px]:gap-2" automation-id="contactEmailFormContent-personalDetails">
      <div class="flex gap-6 justify-between max-[850px]:flex-col max-[850px]:gap-2" automation-id="contactEmailFormContent-personalDetailsNames">
        <udex-text-field
          :label="t('contact.me.first.name')"
          :value="form.fields.firstName"
          @input="(event) => onInputValidation(event, 'firstName')"
          :value-state="firstNameValueState"
          maxlength="100"
          aria-describedby="firstNameError"
          required
          @blur="validateField('firstName')"
          :supporting-text="firstNameSupportingText"
          automation-id="contactEmailFormContent-firstName"
        />
        <udex-text-field
          :label="t('contact.me.last.name')"
          :value="form.fields.lastName"
          @input="(event) => onInputValidation(event, 'lastName')"
          :value-state="lastNameValueState"
          maxlength="100"
          aria-describedby="lastNameError"
          required
          @blur="validateField('lastName')"
          :supporting-text="lastNameSupportingText"
          automation-id="contactEmailFormContent-lastName"
        />
      </div>
      <div class="flex gap-6 justify-between max-[850px]:flex-col max-[850px]:gap-2" automation-id="contactEmailFormContent-personalDetailsContacts">
        <udex-text-field
          :label="t('contact.me.mail')"
          :value="form.fields.email"
          @input="(event) => onInputValidation(event, 'email')"
          :value-state="emailValueState"
          maxlength="100"
          aria-describedby="emailError"
          required
          @blur="checkEmailField"
          :supporting-text="emailSupportingText"
          automation-id="contactEmailFormContent-email"
        />
        <udex-phone-number-field
          id="udex-phone-number-field"
          :label="t('contact.me.phone.placeholder')"
          :value="form.fields.phone"
          :blockedCountryCodes="EMBARGO_COUNTRIES"
          @change="onPhoneEntered"
          field-state="Standard"
          height="200px"
          automation-id="contactEmailFormContent-phoneNumber"
        />
      </div>
    </div>
    <div
      class="HeadingMediumXXXS-size mb-4"
      automation-id="contactEmailFormContent-companyDetailsTitle"
    >
      {{ t("contact.me.modal.right.title") }}
    </div>
    <div class="flex flex-col gap-1.5 mb-1" automation-id="contactEmailFormContent-companyDetails">
      <div class="flex gap-6 max-[850px]:flex-col max-[850px]:gap-2" automation-id="contactEmailFormContent-companyDetailsInputs">
        <udex-text-field
          :label="t('contact.me.company.name')"
          :value="form.fields.company"
          @input="(event) => onInputValidation(event, 'company')"
          :value-state="companyValueState"
          maxlength="100"
          aria-describedby="companyError"
          required
          @blur="validateField('company')"
          :supporting-text="companySupportingText"
          automation-id="contactEmailFormContent-companyName"
        />
        <udex-country-selector
          id="country-selector"
          :value="form.fields.country"
          :value-state="countryValueState"
          @selection-change="onCountrySelect"
          width="380px"
          height="200px"
          search-placeholder="Search Countries and Regions"
          :label="t('contact.me.country')"
          required
          @blur="validateField('country')"
          :supporting-text="countrySupportingText"
          :blockedCountryCodes="EMBARGO_COUNTRIES"
          automation-id="contactEmailFormContent-countrySelector"
        />
      </div>
      <div class="flex gap-6 max-[850px]:flex-col max-[850px]:gap-2" automation-id="contactEmailFormContent-companyDetailsInputs">
        <udex-select-box
          mode="SingleSelect"
          :value="form.fields.relationshipToSap"
          @selection-change="onRelationshipToSapSelect"
          :label="t('contact.me.relation.to.sap')"
          required
          @blur="validateField('relationshipToSap')"
          :value-state="relationshipToSapValueState"
          :supporting-text="relationshipToSapSupportingText"
        >
          <udex-select-box-item v-for="(option, index) in RELATIONSHIP_TO_SAP_OPTIONS" 
            :key="index" :label="t(`contact.me.select.box.item.${option}`)" />
        </udex-select-box>
      </div>
    </div>
    <udex-textarea
      id="textarea"
      label="Your message"
      :value="form.fields.comments"
      @input="(event) => onInputValidation(event, 'comments')"
      :maxlength.prop="MAX_CHAR"
      required
      :field-state="commentsFieldState"
      @blur="validateField('comments')"
      :supporting-text="commentsSupportingText"
      automation-id="contactEmailFormContent-comments"
    />
    <PFContactEmailFormAgreement automation-id="contactEmailFormContent-agreement" />
    <vue-recaptcha
      class="mt-6"
      ref="recaptcha"
      @verify="onVerify"
      @expired="resetRecaptcha"
      @error="recaptchaFailed"
      :sitekey="siteKey"
      :loadRecaptchaScript="false"
      automation-id="contactEmailFormContent-recaptcha"
    >
    </vue-recaptcha>
    <PFContactEmailFormResponseErrorMessage
      class="mt-6"
      v-if="requestStatus === false"
      automation-id="contactEmailFormContent-responseErrorMessage"
    />
    <udex-button
      class="mt-6"
      id="custom-usage-patterns-forms-submit"
      accessible-name="Contact Sales Submit"
      type="Submit"
      size="Large"
      :disabled.prop="isButtonDisabled"
      @click="sendRequest"
      udex-button
      automation-id="contactEmailFormContent-submitButton"
    >
      {{ t("contact.me.send.request") }}
    </udex-button>
  </form>
</template>

<style lang="less" scoped>
udex-text-field,
udex-phone-number-field,
udex-country-selector {
  width: 100%;
  min-height: 70px;
}

udex-select-box {
  width: 48.7%;
  min-height: 70px;
}

udex-phone-number-field {
  --udex-phone-number-field-min-width: 200px;
}

udex-country-selector {
  --udex-country-selector-min-width: 200px;
}

udex-textarea {
  --udex-textarea-height: 258px;
}

udex-select-box {
  width: 48.556%;
}

@media (max-width: 850px) {
  udex-select-box {
    width: 100%;
  }
}
</style>
