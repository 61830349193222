<script>
import PFSpecializationTblComponent from './PFSpecializationTblComponent.vue';
import PFBaseModal from '@/components/common/modal/PFBaseModal.vue';
import PFCompetencyLevelImage from '@/components/common/modal/PFCompetencyLevelImage.vue';
import PFCompetencyLevelDescription from '@/components/common/modal/PFCompetencyLevelDescription.vue';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFERPModalComponent',
  components: {
    PFSpecializationTblComponent,
    PFBaseModal,
    PFCompetencyLevelImage,
    PFCompetencyLevelDescription,
  },
  data: () => ({
    faqLinkSolution: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
  }),
  props: {
    modalHeader: { type: String, default: '' },
    competencyLevel: { type: String, default: '' },
    L2: {
      type: Object,
      default: () => ({}),
    },
    toggleModal: { type: Function, default: () => {} },
    hasCompetency: { type: Boolean, default: () => false },
  },
  computed: {
    hasCompetencyLevel() {
      return this.hasCompetency && this.competencyLevel?.toUpperCase() !== 'ZERO';
    },
    hasSpecialization() {
      return this.L2.specializations?.length > 0;
    },
    getModalHeader() {
      if (this.L2.solutionL2Key === 'PFERPHCPU') {
        return this.$t('GwS.modal.header');
      } if (this.L2.solutionL2Key === 'PFERPHCPE') {
        return this.$t('RwS.modal.header');
      }
      return this.modalHeader;
    },
  },

};
</script>

<template>
  <PFBaseModal :modalHeaderText="getModalHeader" :toggleModal :messageBoxStyleOnMobile="!hasCompetency" automationID="PFERPModalComponent">
    <PFCompetencyLevelImage v-if="hasCompetencyLevel" :competencyLevel />
    <div class="competency-level-description">
      <PFCompetencyLevelDescription :hasCompetency :competencyLevel :learnMoreLink="faqLinkSolution" />
    </div>
    <PFSpecializationTblComponent v-if="hasCompetencyLevel" :specialization="L2.specializationData" :ERPMode="true"
      :showTableInitial="true" />
  </PFBaseModal>
</template>

<style lang="less" scoped>
.competency-level-description{
  padding: 20px 0 30px 0;
}
@media (min-width:640px) {
  .competency-level-description {
      padding: 20px 0 24px 0;
    }
}
</style>
