<script>
import { mapState, mapActions } from 'vuex';
import '@sapudex/web-components/dist/FilterMenu.js';
import '@sapudex/web-components/dist/FilterMenuItem.js';
import '@sapudex/web-components/dist/FilterMenuGroupHeader.js';
import '@sapudex/web-components/dist/FilterMenuSelectAll.js';
import { getShowButtonText } from '@/utils/filterUtils';

export default {
  name: 'PFMultiSelectCheckboxDropDownComponent',
  props: {
    filterType: { type: String, default: '' },
    title: { type: String, default: '' },
  },
  data: () => ({
    selectedItemObject: { type: Object, default: () => {} },
    lastEvent: null,
    renderKey: 0,
  }),
  watch: {
    selectedLocations: {
      handler() {
        this.updateLocaleStorageWithSelectedLocations();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateLocaleStorageWithSelectedLocations();
  },
  computed: {
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
    }),
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      filterOptions: (state) => state.filterOptions,
      selectedOptions: (state) => state.selectedOptions,
      selectedLocations: (state) => state.selectedOptions.location,
      selectedDropdownOptions: (state) => [
        ...state.selectedOptions.industry,
        ...state.selectedOptions.engagement,
        ...state.selectedOptions.location,
        ...state.selectedOptions.designation,
        ...state.selectedOptions.isCompetency,
      ],
    }),

    activeOptions() {
      return this.filterOptions[this.filterType].active;
    },
    disabledOptions() {
      return this.filterOptions[this.filterType].disabled;
    },
    isSolutionOptionSelected() {
      return [...this.solutionBadge.keys()]?.length > 0;
    },
    isAnyFilterSelected() {
      return this.isSolutionOptionSelected || this.selectedDropdownOptions.length > 0;
    },
    showButtonText() {
      return getShowButtonText(this.isAnyFilterSelected, this.countResults);
    },
    badgeCounter() {
      return this.selectedOptions[this.filterType].length;
    },

  },

  methods: {
    ...mapActions('profileSearch', {
      cleanFilterByType: 'cleanFilterByType',
    }),

    onFilterReset(filterType) {
      this.cleanFilterByType(filterType);
      this.lastEvent = null;
      this.renderKey++;
    },
    onFilterCloseOutside() {
      this.renderKey++;
    },

    handleSelectionChange(event) {
      this.lastEvent = event;
      const selectedItem = [event.detail.item];
      const description = selectedItem[0]._state.supportingText;
      const designationDescr = selectedItem[0]._state.label;
      const isSelected = selectedItem[0]._state.selected;
      const title = selectedItem[0].accessKey;
      this.selectedItemObject = {
        description,
        designationDescr,
        isSelected,
        title,
      };
      this.selectOption(this.selectedItemObject);
      // Key increment technique is used to rerender components and reset search input upon closing the filter, due to the absence of UDEx props to do it.
      this.renderKey++;
    },
    selectOption(_option) {
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      const option = { ..._option };

      if (!option.isSelected) {
        option.optionType = this.filterType;
        this.$store.dispatch('profileSearch/uncheckOption', option);
      } else {
        this.$store.dispatch('profileSearch/addSelectedOption', { optionType: this.filterType, value: option });
      }
      this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
    },

    updateLocaleStorageWithSelectedLocations() {
      if (this.filterType === 'location') {
        const countryNames = this.selectedOptions.location.map((item) => item.title);
        localStorage.setItem('selectedLocation', JSON.stringify(countryNames));
      }
    },
  },
};
</script>

<template>
  <div class="preview-filtering">
    <udex-filter-menu
      :key="renderKey"
      id="filter-menu"
      mode="MultiSelect"
      height="290px"
      :search-placeholder="$t('filter.search.input.placeholder')"
      :filter-name="title"
      :result-btn-label="showButtonText"
      @after-close="onFilterCloseOutside"
      @selection-change="handleSelectionChange"
      @reset-click="onFilterReset(filterType)"
      disable-target-overlap="true"
      :badgeValue.prop="badgeCounter"

      >
      <udex-filter-menu-item
        v-for="activeOption in activeOptions"
        :key="activeOption.title"
        :accessKey="activeOption.title"
        :label="activeOption.designationDescr || activeOption.title"
        :supporting-text="activeOption.description"
        :selected.prop="Boolean(activeOption.isSelected)"
      ></udex-filter-menu-item>
      <udex-filter-menu-item
        v-for="disabledOption in disabledOptions"
        :key="disabledOption.title"
        :accessKey="disabledOption.title"
        :label="disabledOption.designationDescr || disabledOption.title"
        :supporting-text="disabledOption.description"
        disabled
      ></udex-filter-menu-item>
    </udex-filter-menu>
  </div>
</template>
<style lang="less" scoped>
udex-filter-menu{
  --udex-filter-menu-popover-max-width:360px;
}
</style>
