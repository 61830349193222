<script>
/* eslint-disable no-undef */
import { mapState } from 'vuex';
import PEPartnerDetailHeaderComponent from '@/components/editor/PEPartnerDetailHeaderComponent';
import PFPartnerValuePropComponent from '@/components/common/PFPartnerValuePropComponent';
import PFServiceValuePropComponent from '@/components/common/PFServiceValuePropComponent';
import PFCountryAccordionComponent from '@/components/common/PFCountryAccordionComponent';
import PFSingleLocationComponent from '@/components/common/PFSingleLocationComponent';
import PFSocialMediasBarComponent from '@/components/common/PFSocialMediasBarComponent';
import PEEditableListComponent from '@/components/common/PEEditableListComponent';
import PFItemListComponent from '@/components/common/PFItemListComponent';
import PEGlanceVideoComponent from '@/components/editor/PEGlanceVideoComponent';
import PFProfileResourcesComponent from '@/components/common/PFProfileResourcesComponent';
import PEHeadquartersAddressComponent from '@/components/editor/PEHeadquartersAddressComponent';
import PEContactEmailComponent from '@/components/editor/PEContactEmailComponent';
import PEKeywordsComponent from '@/components/editor/PEKeywordsComponent';
import PEGuidanceTextComponent from '@/components/editor/popup/PEGuidanceTextComponent';
// TODO: Below PEPlatinumPopupComponent andPFCompetencyServiceComponent component does not used. Investigate if it could be deprecated.
import PEPlatinumPopupComponent from '@/components/editor/popup/PEPlatinumPopupComponent';
import PFCompetencyServiceComponent from '@/components/common/PFCompetencyServiceComponent';
import PFCompetencyAtAGlanceComponent from '@/components/common/PFCompetencyAtAGlanceComponent';
import PFQPPSGlanceComponent from '@/components/common/PFQPPSGlanceComponent';
import PFLocationQPPSComponent from '@/components/common/PFLocationQPPSComponent';
import PFSolutionCompetencyComponent from '@/components/common/PFSolutionCompetencyComponent';
import PFIECompetencyComponent from '../common/PFIECompetencyComponent.vue';
import PFRecognitionsComponent from '@/components/common/PFRecognitionsComponent';

const NODE_ENV = PROCESS_NODE_ENV;

export default {
  name: 'PEContentAreaComponent',
  props: {
    location: {
      type: String,
      default: 'partnerDetail#',
    },
  },
  data: () => ({
    closePublishConceptPopup: false,
    nodeEnv: NODE_ENV,
    partnerLevelModal: false,
    qppsVpucCountExist: false,
    competencyExist: false,
    recognitionsVisible: false,
  }),
  components: {
    PFSocialMediasBarComponent,
    PEPartnerDetailHeaderComponent,
    PFPartnerValuePropComponent,
    PFServiceValuePropComponent,
    PFCountryAccordionComponent,
    PFSingleLocationComponent,
    PEEditableListComponent,
    PFItemListComponent,
    PEGlanceVideoComponent,
    PFProfileResourcesComponent,
    PEHeadquartersAddressComponent,
    PEContactEmailComponent,
    PEKeywordsComponent,
    PEPlatinumPopupComponent,
    PEGuidanceTextComponent,
    PFCompetencyServiceComponent,
    PFCompetencyAtAGlanceComponent,
    PFQPPSGlanceComponent,
    PFLocationQPPSComponent,
    PFSolutionCompetencyComponent,
    PFIECompetencyComponent,
    PFRecognitionsComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      profileData: (state) => state.profileData,
      updatedProfileData: (state) => state.updatedProfileData,
      solutions: (state) => state.profileData.solutions,
      businessModels: (state) => state.profileData.businessModels,
      localProfiles: (state) => state.profileData.localProfiles,
      competencies: (state) => state.profileData.competencies,
      solutionTransformed: (state) => state.solutionTransformed,
      ieCompetency: (state) => state.profileData.ieCompetency,
      growCountries: (state) => state.profileData.growWithSap,
    }),
    ...mapState('fullData', {
      allIndustries: (state) => state.allIndustries,
      allSupportedLanguages: (state) => state.allSupportedLanguages,
    }),
    multipleCountries() {
      return this.localProfiles && this.localProfiles.length > 1;
    },
  },
  methods: {
    updateQppsVpucExist(exist) {
      this.qppsVpucCountExist = exist;
    },
    updateCompetencyExist(exist) {
      this.competencyExist = exist;
    },
    updateRecognitionsVisible(isVisible) {
      this.recognitionsVisible = isVisible;
    },
  },
};
</script>

<template>
  <div class="editor-container">
    <div class="partner-detail-page ds-flexCol-12 ds-p-none">
      <PEPartnerDetailHeaderComponent
        :profile="profileData"
        id="partner-detail-header"
        class="partner-detail-page--sticky-header"
      />
      <PEGuidanceTextComponent />
      <div class="content ds-flexRow ds-m-none">
        <div id="partner-detail-section-glance" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="ds-flexRow content-row ds-m-none">
            <div class="ds-flexCol-12 ds-flexCol-lg ds-p-none ds-p-r-m ds-p-y-xxl">
              <PFPartnerValuePropComponent :editMode="true" />
              <PEContactEmailComponent />
              <div class="consultant-implementation-glance-section">
                <PFCompetencyAtAGlanceComponent
                  v-if="!this.$hideCompetencyEnv && competencies && competencies.length > 0"
                  @update-competency-exist="updateCompetencyExist"
                  :competencies="solutionTransformed"
                  :editMode="true"
                  :growWithSap="growCountries?.length > 0"
                />
                <PFQPPSGlanceComponent v-if="!this.$hideQPPSEnv" @update-qpps-vpuc-exist="updateQppsVpucExist" />
                <span
                  v-if="(this.competencyExist || this.qppsVpucCountExist > 0) && this.recognitionsVisible"
                  class="competency-qpps-spacer"
                ></span>
                <PFRecognitionsComponent
                  @update-recognitions-visible="updateRecognitionsVisible"
                  :growCountries="growCountries"
                  :localProfiles="localProfiles"
                />
              </div>
            </div>
            <div class="ds-flexCol-12 ds-flexCol-lg video-col">
              <PEGlanceVideoComponent :profileId="profileData.profileId" />
            </div>
          </div>
        </div>
        <div id="partner-detail-section-service" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="section-content ds-flexCol-12 ds-p-none">
            <h3 class="ds-heading--l section-header-font-size ds-m-t-xl">{{ $t("section.title.services") }}</h3>
            <div class="ds-flexCol-12 sevice-prop-section ds-p-none">
              <PFServiceValuePropComponent :editMode="true" />
            </div>
            <div
              class="ds-flexCol-12 solution-competency-section ds-p-none"
              automation-id="profileIEcompetencyComponent"
            >
              <PFIECompetencyComponent
                v-if="!this.$hideIECompetencyEnv && ieCompetency?.ieSpecializations?.length > 0"
                :ieSpecializations="ieCompetency.ieSpecializations"
                :level="ieCompetency.level"
              />
            </div>
            <div class="ds-flexCol-12 solution-competency-section ds-m-t-s ds-p-none"></div>
            <div class="ds-flexCol-12 solution-competency-section ds-p-none">
              <PFSolutionCompetencyComponent
                v-if="solutionTransformed && solutionTransformed.length > 0"
                :solutionTransformed="solutionTransformed"
              />
            </div>
            <div class="ds-flexRow ds-m-b-l service-row-max-width service-editable-list">
              <div
                class="ds-flexCol ds-m-b-xl"
                :class="
                  competencies && competencies.length > 0 && !this.$hideCompetencyEnv
                    ? 'ds-flexCol-xs-3'
                    : 'ds-flexCol-xs-4'
                "
              >
                <PEEditableListComponent
                  idKey="industryId"
                  textKey="title"
                  :listHeading="$t('service.section.title.focus.indutries')"
                  :items="profileData.industries"
                  :allItems="allIndustries"
                  selectAction="partnerProfile/updateFocusIndustries"
                  removeAction="partnerProfile/removeFocusIndustry"
                  reorderAction="partnerProfile/reorderFocusIndustries"
                  :helpText="$t('service.section.helptext.focus.industries.edit')"
                  :mandatoryCheck="false"
                />
              </div>
              <div class="ds-flexCol ds-m-b-xl ds-flexCol-xs-4">
                <PFItemListComponent
                  :listTitle="$t('service.section.business.models.title')"
                  :moreTitle="$t('service.section.business.models.more')"
                  :items="businessModels"
                  itemKey="id"
                  :edit-mode="true"
                  :helpText="$t('service.section.helptext.business.models.edit')"
                  itemTextKey="title"
                  descriptionTextKey="description"
                />
              </div>
              <div class="ds-flexCol ds-m-b-xl ds-flexCol-xs-4">
                <PEEditableListComponent
                  idKey="language"
                  textKey="text"
                  :listHeading="$t('service.section.title.supported.languages')"
                  :items="profileData.languages"
                  :allItems="allSupportedLanguages"
                  selectAction="partnerProfile/updateSupportedLanguages"
                  removeAction="partnerProfile/removeSupportedLanguages"
                  reorderAction="partnerProfile/reorderSupportedLanguages"
                  :helpText="$t('service.section.helptext.languages.edit')"
                  :tooltipText="$t('service.section.tooltip.languages.edit')"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="partner-detail-section-locations" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="section-content ds-flexCol-12 ds-m-b-xxl ds-p-none">
            <h3 class="ds-heading--l ds-m-b-l section-header-font-size ds-m-t-xl">
              {{ $t("section.title.locations") }}
            </h3>
            <PFSocialMediasBarComponent :editMode="true" />
            <PEHeadquartersAddressComponent v-if="multipleCountries" :initial-data="profileData" />
            <PFCountryAccordionComponent v-if="localProfiles && localProfiles.length > 1" :editMode="true" />
            <PFSingleLocationComponent
              v-else-if="localProfiles && localProfiles.length === 1"
              :editMode="true"
              :countryProfile="localProfiles[0]"
              :partnerProfileId="profileData.profileId"
            />
          </div>
        </div>
        <div
          v-if="
            localProfiles &&
            localProfiles.length === 1 &&
            localProfiles[0].packagedSolutions &&
            localProfiles[0].packagedSolutions.length > 0
          "
          id="partner-detail-section-QPPS"
          class="partner-detail-section ds-flexCol-10 ds-p-none"
        >
          <div class="section-content ds-flexCol-12 ds-m-b-xxl ds-p-none">
            <PFLocationQPPSComponent :localProfile="false" :getQPPS="localProfiles[0].packagedSolutions" />
          </div>
        </div>
        <div
          id="partner-detail-section-resources"
          class="partner-detail-section ds-flexCol-12 ds-p-none ds-m-none resources-section"
        >
          <div class="section-content ds-m-b-xxl">
            <h3
              class="ds-heading--l section-header-font-size ds-m-none ds-m-t-xl ds-m-b-l"
              id="global-resource-section"
            >
              {{ $t("section.title.resources") }}
            </h3>
            <div class="global-resource-guiding-text">{{ $t("global.resource.guiding.text") }}</div>
            <PFProfileResourcesComponent :edit-mode="true" />
          </div>
        </div>
        <div id="partner-detail-section-keywords" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="section-content ds-flexCol-12 ds-m-b-xxl ds-p-none">
            <h3 class="ds-heading--l section-header-font-size ds-m-none ds-m-t-xl" id="global-keyword-section">
              {{ $t("section.title.keywords") }}
            </h3>
            <PEKeywordsComponent />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.editor-container {
  .partner-detail-page--sticky-header {
    // position: fixed;
    top: 80px;
    width: 100%;
    z-index: 10;
    // position: -webkit-sticky; /* Safari */
    // position: sticky;
  }
  .service-editable-list {
    .ds-flexCol {
      min-width: 325px;
    }
  }
  .consultant-implementation-section {
    .ds-flexCol {
      min-width: 255px;
    }
  }
  .row-content-center {
    justify-content: center;
  }
  .global-resource-guiding-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-1;
    max-width: 817px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ds-p-l-xl-and-xs {
  padding-left: 60px !important;
}

.ds-p-r-none {
  padding-right: 0 !important;
}

.section-content {
  margin: auto;
  max-width: 1182px !important;
}

.content-row {
  width: 100%;
}
</style>
