<script>
import PFSpecializationTblContentComponent from '@/components/common/PFSpecializationTblContentComponent.vue';
import { useScreenSize } from '@/composables/useScreenSize';
import '@sapudex/web-components/dist/AccordionItem.js';

export default {
  name: 'PFSpecializationTblComponent',
  components: {
    PFSpecializationTblContentComponent,
  },
  props: {
    specialization: {
      type: Object,
      default: () => ({}),
    },
    showTableInitial: {
      type: Boolean, default: false,
    },
    ERPMode: {
      type: Boolean, default: false,
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { isScreenSizeLessSM } = useScreenSize();
    return {
      isScreenSizeLessSM,
    };
  },
};
</script>

<template>
  <!-- Accordion with spec. table -->
  <udex-accordion-item v-if="!ERPMode" :title="specialization.name" automation-id="PFSpecializationTblContent-accordion">
    <PFSpecializationTblContentComponent :specialization :showTableInitial :ERPMode :idx />
  </udex-accordion-item>
  <!-- Spec. table without accordion -->
  <PFSpecializationTblContentComponent v-if="ERPMode" :specialization :showTableInitial :ERPMode :idx />
</template>

<style lang="less" scoped>
udex-accordion-item{
  max-height: 44px;
 --udex-accordion-item-border-width:0.5px;
 --udex-accordion-item-headline-font-size:16px;
 --udex-accordion-item-content-margin:0px 16px;
 --udex-accordion-item-headline-color: @color-black;
}
</style>
