<script>
import { openInNewTab } from '@/utils/utils.js';

export default {
  name: 'PFCompetencyLevelDescription',
  props: {
    competencyLevel: { type: String, default: '' },
    hasCompetency: { type: Boolean, default: () => false },
    learnMoreLink: { type: String, default: '' },
  },
  methods: {
    openInNewTab,
  },
};
</script>

<template>
  <div automation-id="PFCompetencyLevelDescription">
    <span class="body-s-size" v-if="hasCompetency && competencyLevel?.toUpperCase() !== 'ZERO'">
      {{ $t("profile.comptency.level.description") }}</span
    >
    <span class="body-s-size" v-if="!hasCompetency"> {{ $t("specialization.ERP.not.available.guideText") }}</span>
    <span class="body-s-size" v-if="hasCompetency && competencyLevel.toUpperCase() === 'ZERO'">
      {{ $t("zero.level.ERP.guide.text") }}</span
    >
    <udex-link accessibleRole="Link" @click.prevent="openInNewTab(learnMoreLink)">
      {{ $t("learn.more") }}
    </udex-link>
  </div>
</template>
