<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import urlLinks from '@/plugins/linkUrl';
import '@sapudex/web-components/dist/Checkbox.js';
import '@sapudex/web-components/dist/StatusMessage.js';

const store = useStore();
const { t } = useI18n();

const partnerProfileData = computed(() => store.state.partnerProfile.profileData);
const agreementText = computed(
  () => ` ${t('contact.me.statement.one')}   ${partnerProfileData.value.name}   ${t('contact.me.statement.two')} *`,
);

const PRIVACY_LINK = urlLinks.PRIVACY_LINK;

const isContactEmailChecked = ref(false);
const checkboxValueState = ref('Standard');

const validateCheckbox = () => {
  checkboxValueState.value = isContactEmailChecked.value ? 'Standard' : 'Error';
};

const onCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  isContactEmailChecked.value = isChecked;
  store.dispatch('contactEmail/updateContactEmailCheckBox', isChecked);
  validateCheckbox();
};

watch(isContactEmailChecked, validateCheckbox);
</script>

<template>
  <div class="agreement body-s-size" automation-id="contactEmailForm-agreement">
    <div class="checkbox-agreement-wrapper" automation-id="contactEmailForm-checkboxWrapper">
      <udex-checkbox
        v-model="isContactEmailChecked"
        required
        :value-state="checkboxValueState"
        @change="onCheckboxChange"
        automation-id="contactEmailForm-checkbox"
      />
      <span automation-id="contactEmailForm-agreementText">
        {{ agreementText }}
        <udex-link
          :href="PRIVACY_LINK"
          target="_blank"
          accessibleRole="Link"
          automation-id="contactEmailForm-privacyLink"
        >
          {{ $t("contact.me.statement.three") }}
        </udex-link>
      </span>
    </div>
    <udex-status-message
      v-if="checkboxValueState === 'Error'"
      :supporting-text="t('mandatory.field.error.message')"
      value-state="Error"
      automation-id="contactEmailForm-statusMessage"
    >
    </udex-status-message>
  </div>
</template>

<style scoped>
udex-checkbox {
  min-width: 46px;
}
.agreement {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.checkbox-agreement-wrapper {
  display: flex;
}
</style>
