<script>
import { mapActions, mapState } from 'vuex';
import { computed } from 'vue';
import { useScreenSize } from '@/composables/useScreenSize';
import '@sapudex/web-components/dist/Pagination.js';

export default {
  name: 'PFPaginationComponent',
  computed: {
    ...mapState('profileSearch', {
      currentPage: (state) => state.pageNumber,
      countPages: (state) => state.countPages,
    }),
  },
  setup() {
    const { isScreenSizeLessSM } = useScreenSize();

    const paginationType = computed(() => (isScreenSizeLessSM.value ? 'short' : 'full'));
    const paginationDisplay = computed(() => (isScreenSizeLessSM.value ? 'desktop' : 'mobile'));
    const absoluteNavigation = computed(() => (!isScreenSizeLessSM.value));
    return {
      paginationType,
      paginationDisplay,
      absoluteNavigation,
    };
  },
  methods: {
    ...mapActions('profileSearch', {
      nextPage: 'nextPage',
      prevPage: 'prevPage',
      goToPage: 'goToPage',
    }),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },

  },
};
</script>

<template>
    <div class="pagination">
      <udex-pagination
        id="pagination"
        @change="
          (e) => {
            goToPage(e.detail.currentPage);
          }
        "
        :pages="countPages"
        :currentPageIndex="currentPage - 1"
        :type="paginationType"
        :absoluteNavigation.prop="absoluteNavigation"
        :display="paginationDisplay"
      ></udex-pagination>
    </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

#pagination .udex-pagination__page {
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
}
#pagination {
  margin-top: 32px;
}
@media (min-width: 640px) and (max-width: 979px) {
  #pagination {
    margin-top: 16px;
  }
}
</style>
