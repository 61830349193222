<script>
import { mapState } from 'vuex';

import axios from 'axios';
import PFCompetencyAtAGlanceComponent from '@/components/common/PFCompetencyAtAGlanceComponent';
import PFPartnerDetailHeaderComponent from '@/components/common/PFPartnerDetailHeaderComponent';
import PFPartnerValuePropComponent from '@/components/common/PFPartnerValuePropComponent';
import PFItemListComponent from '@/components/common/PFItemListComponent';
import PFServiceValuePropComponent from '@/components/common/PFServiceValuePropComponent';
import PFCountryAccordionComponent from '@/components/common/PFCountryAccordionComponent';
import PFSingleLocationComponent from '@/components/common/PFSingleLocationComponent';
import PFSocialMediasBarComponent from '@/components/common/PFSocialMediasBarComponent';
import PFProfileResourcesComponent from '@/components/common/PFProfileResourcesComponent';
import PFHeadquartersAddressComponent from '@/components/common/PFHeadquartersAddressComponent';
import PFContactEmailComponent from '@/components/common/PFContactEmailComponent';
import PFQPPSGlanceComponent from '@/components/common/PFQPPSGlanceComponent';
import PFLocationQPPSComponent from '@/components/common/PFLocationQPPSComponent';
import PFSolutionCompetencyComponent from '@/components/common/PFSolutionCompetencyComponent';
import PFIECompetencyComponent from '@/components/common/PFIECompetencyComponent';
import PFRecognitionsComponent from '@/components/common/PFRecognitionsComponent';
import PFBrandNamesModalComponent from '@/components/common/PFBrandNamesModalComponent';

const NODE_ENV = PROCESS_NODE_ENV;
const SHOW_COMPETENCY_ENV = 'none';
const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFContentAreaComponent',
  components: {
    PFBrandNamesModalComponent,
    PFProfileResourcesComponent,
    PFPartnerDetailHeaderComponent,
    PFPartnerValuePropComponent,
    PFItemListComponent,
    PFServiceValuePropComponent,
    PFCountryAccordionComponent,
    PFSocialMediasBarComponent,
    PFSingleLocationComponent,
    PFHeadquartersAddressComponent,
    PFContactEmailComponent,
    PFCompetencyAtAGlanceComponent,
    PFQPPSGlanceComponent,
    PFLocationQPPSComponent,
    PFSolutionCompetencyComponent,
    PFIECompetencyComponent,
    PFRecognitionsComponent,
  },
  props: {
    location: {
      type: String,
      default: 'partnerDetail#',
    },
    partnerProfileData: {
      type: Object,
      default: () => {
      },
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPlaying: true,
    videoLoading: false,
    nodeEnv: NODE_ENV,
    showCompetencyEnv: SHOW_COMPETENCY_ENV,
    partnerLevelModal: false,
    helpTextLinks: urlLinks.HELP_TEXT_LEARN_MORE,
    qppsVpucCountExist: false,
    competencyExist: false,
    recognitionsVisible: false,
    alternativeNamesModalOpen: false,
  }),
  methods: {
    toggleAlternativeNamesModal() {
      this.alternativeNamesModalOpen = !this.alternativeNamesModalOpen;
    },
    onPlayerPlay() {
      if (this.isPlaying) {
        this.isPlaying = false;
        if (this.$refs.videoRef.readyState === 4) {
          this.$refs.videoRef.play();
        } else {
          this.fetchVideo(this.$refs.videoRef);
          this.$refs.videoRef.load();
          this.$refs.videoRef.oncanplaythrough = () => {
            this.videoLoading = false;
            this.$refs.videoRef.play();
          };
          this.videoLoading = true;
        }
      } else {
        this.isPlaying = true;
        this.$refs.videoRef.pause();
      }
    },
    fetchVideo(video) {
      axios.get(this.$store.state.partnerProfile.profileData.videoUrl,
        { responseType: 'blob' })
        .then((response) => {
          video.src = URL.createObjectURL(response.data);
        })
        .catch((err) => {
          video.src = this.$store.state.partnerProfile.profileData.videoUrl;
          console.log(err);
        });
    },
    fetchPoster(video) {
      axios.get(this.videoImageUrl,
        { responseType: 'blob' })
        .then((response) => {
          video.poster = URL.createObjectURL(response.data);
          this.videoLoading = false;
        })
        .catch((err) => {
          video.poster = this.videoImageUrl;
          console.log(err);
        });
    },
    videoLoad() {
      this.fetchPoster(this.$refs.videoRef);
    },
    updateQppsVpucExist(exist) {
      this.qppsVpucCountExist = exist;
    },
    updateCompetencyExist(exist) {
      this.competencyExist = exist;
    },
    updateRecognitionsVisible(isVisible) {
      this.recognitionsVisible = isVisible;
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      solutionTransformed: (state) => state.solutionTransformed,
      ieCompetency: (state) => state.profileData.ieCompetency,
      growCountries: (state) => state.profileData.growWithSap,
    }),
    supportedLanguages: {
      get() {
        return this.$store.state.partnerProfile.profileData.languages;
      },
    },
    focusIndustries: {
      get() {
        return this.$store.state.partnerProfile.profileData.industries;
      },
    },
    solutions: {
      get() {
        return this.$store.state.partnerProfile.profileData.solutions;
      },
    },
    localProfiles: {
      get() {
        return this.$store.state.partnerProfile.profileData.localProfiles;
      },
    },
    videoSrc: {
      get() {
        return this.$store.state.partnerProfile.profileData.videoUrl;
      },
    },
    businessModels: {
      get() {
        return this.$store.state.partnerProfile.profileData.businessModels;
      },
    },
    videoUrl: {
      get() {
        return this.$store.state.partnerProfile.profileData.videoUrl;
      },
    },
    videoImageUrl: {
      get() {
        return this.$store.state.partnerProfile.profileData.videoImageUrl;
      },
    },
    competencies: {
      get() {
        return this.$store.state.partnerProfile.profileData.competencies;
      },
    },
    resources: {
      get() {
        return this.$store.state.partnerProfile.profileData.resources;
      },
    },
    showSolution() {
      return this.solutions && this.solutions.length > 0;
    },
  },
};
</script>

<template>
  <div class="contentarea-container">
    <div class="partner-detail-page">
      <PFPartnerDetailHeaderComponent
        id="partner-detail-header"
        :class="isPreviewMode ? 'partner-detail-preview-sticky' : 'partner-detail-sticky'"
        :partnerProfileData="partnerProfileData"
        :isPreviewMode="isPreviewMode"
        :toggleAlternativeNamesModal="toggleAlternativeNamesModal"
      />
      <PFPartnerDetailHeaderComponent
        id="partner-detail-header-small"
        :partnerProfileData="partnerProfileData"
        :isSmallScreenMode="true"
        :toggleAlternativeNamesModal="toggleAlternativeNamesModal"
      />
      <div>
      <PFBrandNamesModalComponent v-if="alternativeNamesModalOpen"
        :brandNames="partnerProfileData.brandNames"
        :toggleAlternativeNamesModal="toggleAlternativeNamesModal"
        :alternativeNamesModalOpen="alternativeNamesModalOpen"
      />
    </div>
      <div class="content ds-flexRow ds-m-none">
        <div id="partner-detail-section-glance" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="ds-flexRow content-row ds-m-none">
          <div class="ds-flexCol-12 ds-flexCol-lg ds-p-none ds-p-r-m ds-p-y-xxl">
            <PFPartnerValuePropComponent :editMode="false"/>
            <PFContactEmailComponent/>
            <div class="consultant-implementation-glance-section">
              <PFCompetencyAtAGlanceComponent
                v-if="!this.$hideCompetencyEnv && competencies && competencies.length > 0"
                @update-competency-exist="updateCompetencyExist"
                :competencies="solutionTransformed"
                :growWithSap="growCountries?.length > 0"
              />
              <PFQPPSGlanceComponent v-if="!this.$hideQPPSEnv" @update-qpps-vpuc-exist="updateQppsVpucExist"/>
              <span
                v-if="(this.competencyExist || this.qppsVpucCountExist > 0) && this.recognitionsVisible"
                class="competency-qpps-spacer"></span>
              <PFRecognitionsComponent
                @update-recognitions-visible="updateRecognitionsVisible"
                :growCountries="growCountries"
                :localProfiles="localProfiles"
              />
            </div>
          </div>
          <div class="ds-flexCol-12 ds-flexCol-lg ds-p-none ds-p-y-xxl video-col">
            <div class="video-upload-container">
              <img v-if="videoImageUrl && !videoUrl" :src="videoImageUrl" class="pf-media-box"
              style="width:608px; height:100%; object-fit:cover;"/>
              <video controls v-if="videoUrl"
                    :src="videoSrc"
                    ref="videoRef"
                    class="pf-media-box"
                    height="342"
                    preload="none"
                    width="608"
                    :poster="videoImageUrl"
                    >
                <source type="video/mp4"/>
                <source type="video/ogg"/>
              </video>
              <div v-show="videoLoading" class="pf-file-upload-loading-indicator">
                <i class="ds-loading-indicator m-r-xs ds-m-r-s"></i>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div id="partner-detail-section-service" class="profile-page-section">
          <div class="section-content services-section">
            <div class="profile-page-services">
              <h2 class="profile-page-section-title">{{$t('section.title.services')}}</h2>
              <PFServiceValuePropComponent :editMode="false"/>
            </div>
            <div class="ds-flexCol-12 solution-competency-section ds-p-none">
              <PFIECompetencyComponent
              v-if="!this.$hideIECompetencyEnv && ieCompetency?.ieSpecializations?.length > 0"
                :ieSpecializations="ieCompetency.ieSpecializations"
                :level="ieCompetency.level"/>
            </div>
            <div class="ds-flexCol-12 solution-competency-section ds-m-t-s ds-p-none">
              <PFSolutionCompetencyComponent
              v-if="solutionTransformed && solutionTransformed.length > 0"
                :solutionTransformed="solutionTransformed"/>
            </div>
            <div class="ds-flexRow ds-m-b-l detail-service-list service-row-max-width">
              <div class="service-list-max-width ds-flexCol ds-m-b-xl ds-flexCol-s-4">
                <PFItemListComponent
                  :helpText="$t('service.section.helptext.focus.industries.preview')"
                  :helpTextLink="helpTextLinks.INDUSTRY_FOCUS"
                  :items="focusIndustries"
                  :listTitle="$t('service.section.title.focus.indutries')"
                  :moreTitle="$t('service.section.title.indutries')"
                  itemKey="industryId"
                  itemTextKey="title"
                />
              </div>
              <div class="service-list-max-width ds-flexCol ds-m-b-xl ds-flexCol-s-4">
                <PFItemListComponent
                  :helpText="$t('service.section.helptext.business.models.preview')"
                  :helpTextLink="helpTextLinks.SERVICES"
                  :items="businessModels"
                  :listTitle="$t('service.section.business.models.title')"
                  :moreTitle="$t('service.section.business.models.more')"
                  descriptionTextKey="description"
                  itemKey="id"
                  itemTextKey="title"
                />
              </div>
              <div class="service-list-max-width ds-flexCol ds-m-b-xl ds-flexCol-s-4">
                <PFItemListComponent
                  :helpText="$t('service.section.helptext.languages.preview')"
                  :helpTextLink="helpTextLinks.SUPPORTED_LANGUAGES"
                  :items="supportedLanguages"
                  :listTitle="$t('service.section.title.supported.languages')"
                  :moreTitle="$t('service.section.title.languages')"
                  itemKey="language"
                  itemTextKey="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="partner-detail-section-locations" class="partner-detail-section ds-flexCol-10 ds-p-none">
          <div class="section-content ds-flexCol-12 ds-m-b-xxl ds-p-none">
            <h3 class="ds-heading--l ds-m-b-l ds-m-t-xl section-header-font-size">{{$t('section.title.locations')}}</h3>
            <PFSocialMediasBarComponent :editMode="false"/>
            <PFHeadquartersAddressComponent/>
            <PFCountryAccordionComponent v-if="localProfiles && localProfiles.length > 1" :editMode="false"/>
            <PFSingleLocationComponent
              v-else-if="localProfiles && localProfiles.length === 1"
              :countryProfile="localProfiles[0]"
              :editMode="false"
              :partnerProfileId="partnerProfileData.profileId"/>
          </div>
        </div>
        <div
          v-if="localProfiles && localProfiles.length === 1
          && localProfiles[0].packagedSolutions && localProfiles[0].packagedSolutions.length > 0"
          id="partner-detail-section-QPPS"
          class="partner-detail-section ds-flexCol-10 ds-p-none"
        >
          <div class="section-content ds-flexCol-12 ds-m-b-xxl ds-p-none">
            <PFLocationQPPSComponent
              :localProfile="false"
              :getQPPS="localProfiles[0].packagedSolutions"/>
          </div>
        </div>
        <div v-if="resources && resources.length > 0" id="partner-detail-section-resources"
          class="partner-detail-section ds-flexCol-12 ds-p-none ds-m-none">
          <div class="section-content resources-section">
            <h3 class="ds-heading--l section-header-font-size">{{$t('section.title.resources')}}</h3>
            <PFProfileResourcesComponent/>
          </div>
        </div>
        <div automation-id="partnerProfilePage-partnerDetailDisclaimer" class="profile-page-section">
          <div class="section-content disclaimer-section">
            <h2 class="profile-page-section-title">{{$t('section.title.disclaimer')}}</h2>
            <div class="body-s-size">{{$t('section.title.disclaimer.text')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
#partner-detail-section-keywords {
  background: none;
}
.service-list-max-width {
  max-width: 100% !important;
}
.profile-page-services,.disclaimer-section{
  max-width: 1182px;
}
.profile-page-services{
  display: flex;
  flex-direction: column;
  justify-self: center;
}
.partner-detail-section {
  line-height: 1.21;
  display: flex;
  margin: auto;
  background: url("~@/assets/Divider_Gradient-01.svg") bottom center no-repeat;
  min-height: 500px;
}

.partner-detail-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
}

.partner-detail-preview-sticky {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  top: 80px;
  width: 100%;
}

.consultant-implementation-glance-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 32px;
}

.competency-qpps-spacer {
  height: 24px;
}

.consultant-implementation-section {
  display: flex;
  margin: auto;
  max-width: 721px !important;
}

#partner-detail-section-glance {
  max-width: 1280px !important;
}

.solution-competency-section {
  max-width: 1280px !important;
  margin-left: auto;
  margin-right: auto;
}
.partner-detail-page {
  .consultant-implementation-section {
    .ds-flexCol {
      min-width: 255px;
    }
  }
  .detail-service-list {
    .ds-flexCol {
      min-width: 325px;
    }
  }
  .resources-section {
    width: 100%;
  }
  .row-content-center {
    justify-content: center;
  }

  .service-row-max-width-small {
    max-width: 690px;
    margin-left : auto !important;
    margin-right: auto !important;
    .ds-flexCol {
      max-width: 300px;
    }
  }

  .services-section {
    display: grid;
    margin-top: 56px;
    margin-bottom: 56px;
    grid-row-gap: 72px;
  }

  .disclaimer-section {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  .profile-page-section-title {
    &:extend(.HeadingMediumM-size);
    color: @udexCoreHeadingDark;
    text-align: center;
    margin-top: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 639px){
    .services-section {
      grid-row-gap: 56px;
    }
    .profile-page-section-title {
      font-size: @udexTypographyHeadingMediumSFontSize;
    }
  }
}
</style>
