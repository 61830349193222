<script>
import AcceptIcon from '@/components/common/base/AcceptIcon.vue';
import { useScreenSize } from '@/composables/useScreenSize';
import '@sapudex/web-components/dist/AccordionItem.js';

export default {
  name: 'PFSpecializationTblContentComponent',
  components: {
    AcceptIcon,
  },
  props: {
    specialization: {
      type: Object,
      default: () => ({}),
    },
    ERPMode: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { isScreenSizeLessSM } = useScreenSize();

    return {
      isScreenSizeLessSM,
    };
  },
  computed: {
    hasCountries() {
      return this.specialization.countries && Object.keys(this.specialization.countries)?.length > 0;
    },
    hasIndustries() {
      return this.specialization.industries && Object.keys(this.specialization.industries)?.length > 0;
    },
    sortedCountries() {
      const countryArray = [];
      if (this.hasCountries) {
        Object.keys(this.specialization.countries).forEach((country) => {
          countryArray.push({ name: country, number: this.specialization.countries[country] });
        });
      }
      countryArray.sort((a, b) => {
        if (a.number - b.number === 0) {
          return a.name.localeCompare(b.name);
        }
        return b.number - a.number;
      });
      return countryArray;
    },
    sortedIndustries() {
      const industryArray = [];
      if (this.hasIndustries) {
        Object.keys(this.specialization.industries).forEach((industry) => {
          industryArray.push({ name: industry, number: this.specialization.industries[industry] });
        });
      }
      industryArray.sort((a, b) => {
        if (a.number - b.number === 0) {
          return a.name.localeCompare(b.name);
        }
        return b.number - a.number;
      });
      return industryArray;
    },
  },
  methods: {
    isLastElement(idx, data) {
      return idx === data.length - 1 ? 'last-element' : null;
    },
    getCountryClass(idx) {
      return {
        'td-border-none': idx !== this.sortedCountries.length - 1,
        'ds-p-b-s': idx === this.sortedCountries.length - 1,
      };
    },
    getIndustryClass(idx) {
      return {
        'ds-p-t-s': idx === 0,
      };
    },
  },
};
</script>

<template>
  <div class="profile-competency-text" automation-id="PFSpecializationTblContent">
    <table class="ds-table ds-m-none profile-erp-table">
      <tbody>
        <tr class="competency-cert">
          <td class="col-name border-bottom body-s-size" :class="{'top-border':ERPMode}">
            {{ $t("cloud.competency.consultants") }}
            <div class="icon-wrapper" v-if="isScreenSizeLessSM">
              <AcceptIcon />
            </div>
          </td>
          <td v-if="!isScreenSizeLessSM" class="col-data border-bottom icon-cell" :class="{'top-border':ERPMode}">
            <AcceptIcon />
          </td>
        </tr>
        <tr>
          <td class="col-name td-border-none">
            <span class="body-s-size profile-competency-project">
              {{ $t("profile.competency.project") }}<br />
              <span class="body-xs-size profile-competency-project-adnotation">{{
                $t("profile.competency.project2")
              }}</span>
            </span>
          </td>
          <td class="col-data td-border-none ds-p-r-none profile-project-table">
            <table class="ds-table">
              <tbody>
                <template v-for="(country, idx) in sortedCountries" :key="country.name + '-number-competency'"
                  ref="numberCountryColumn">
                  <tr v-if="hasCountries" class="number-country-row" :class="isLastElement(idx, sortedCountries)">
                    <td class="ds-p-none country-name" :class="getCountryClass(idx)">
                      <span class="profile-project-number body-s-size ds-m-r-xs">{{ country.number }}</span>
                      <span class="body-s-size ds-m-r-xxs">{{ country.name }}</span>
                    </td>
                  </tr>
                </template>
                <template v-for="(industry, idx) in sortedIndustries" :key="industry.name + '-number-competency'"
                  class="number-competency-column">
                  <tr v-if="hasIndustries" class="number-competency-row" :class="isLastElement(idx, sortedIndustries)">
                    <td class=" ds-p-none td-border-none country-name" :class="getIndustryClass(idx)">
                      <span class="profile-project-number body-s-size ds-m-r-xs">{{ industry.number }}</span>
                      <span class="body-s-size ds-m-r-xxs">{{ industry.name }}</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="less" scoped>
.last-element {
  margin-bottom: 0px !important;
}

tr>td:first-child {
  padding-left: 0px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.ds-table:last-child {
  margin-bottom: 0px !important;
}

.top-border {
  border-top: 1px solid @color-divider-grey;
}

.border-bottom {
  border-bottom: 1px solid @color-divider-grey;
}

.country-name {
  width: 95% !important;
  vertical-align: middle;
  border-color: @color-divider-grey !important;
}

.td-border-none {
  border: none !important;
}

.col-data {
  color: @color-grey-0;
  vertical-align: middle;
  border-color: @color-divider-grey !important;
}

.profile-project-number {
  text-align: right;
  width: fit-content !important;
  font-weight: 500;
}

.col-name {
  &:extend(.font-normal);
  border-color: @color-divider-grey !important;
  width: 40% !important;
}

.ds-table {
  width: 100%;
}

.number-country-row,
.number-competency-row {
  display: flex;
  margin-bottom: 8px;
}

.number-country-column:last-child,
.number-country-column:last-child {
  margin-bottom: 0;
}

@media (max-width: 639px) {
  .ds-table {
    display: flex;
    flex-direction: column;
  }

  .ds-table tr {
    display: flex;
    flex-direction: column;
  }

  .ds-table td {
    width: 100%;
    display: block;
  }

  .col-name {
    width: 100% !important;
  }

  .number-country-row,
  .number-competency-row {
    margin-bottom: 12px;
  }

  .number-country-column:last-child,
  .number-competency-column:last-child {
    margin-bottom: 0;
  }

  .competency-cert {
    display: flex;
    flex-direction: row !important;

    &>td {
      display: flex;
      align-items: center;
    }
  }

  .profile-project-table {
    padding-top: 0px;
    padding-left: 16px;
  }
}
</style>
