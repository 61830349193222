<script>
import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/TableCell.js';
import '@sapudex/web-components/dist/ControlButton.js';

const SHOW_MAX_SOLUTION = 5;
const SHOW_MAX_INDUSTRY = 4;
const QPPS_CATEGORY = 'QPPS_More_Info';

export default {
  name: 'PFQPPSDetailModalComponent',
  data: () => ({
    maxSolution: SHOW_MAX_SOLUTION,
    maxIndustry: SHOW_MAX_INDUSTRY,
    QPPSCategory: QPPS_CATEGORY,
    showAllSolution: false,
    showAllIndustry: false,
  }),
  props: {
    toggleModal: { type: Function, default: () => {} },
    backButtonAction: { type: Function, default: () => {} },
    QPPSDetail: { type: Object, default: () => {} },
    showBackButton: { type: Boolean, default: false },
    QPPSCount: { type: Number, default: 0 },
    VPUCCount: { type: Number, default: 0 },
  },
  computed: {
    VPUC() {
      return this.QPPSDetail.packageType === 'Validated Partner Use Cases';
    },
    moreSolution() {
      const numSolution = this.getPackages(this.QPPSDetail, 'packageProducts').length;
      return numSolution > this.maxSolution ? numSolution - 4 : 0;
    },
    getSolution() {
      if (this.moreSolution > 0 && !this.showAllSolution) {
        return this.getPackagesNoOrder(this.QPPSDetail, 'packageProducts').slice(0, 4);
      }
      return this.getPackagesNoOrder(this.QPPSDetail, 'packageProducts');
    },
    moreIndustry() {
      const numIndustry = this.getPackages(this.QPPSDetail, 'industries').length;
      return numIndustry > this.maxIndustry ? numIndustry - 3 : 0;
    },
    getIndustry() {
      if (this.moreIndustry > 0 && !this.showAllIndustry) {
        return this.getPackages(this.QPPSDetail, 'industries')
          .filter((industry) => industry !== '-')
          .slice(0, 3);
      }
      return this.getPackages(this.QPPSDetail, 'industries').filter((industry) => industry !== '-');
    },
    getModalFeature() {
      const feature = [];
      if (this.QPPSDetail.conversionPackage) {
        feature.push('Conversion to SAP S/4HANA Cloud');
      }
      if (this.QPPSDetail.includesBtpIndustryCloud) {
        feature.push('Industry Cloud');
      }
      if (this.QPPSDetail.intelligentEnterprise) {
        feature.push('Intelligent Enterprise');
      }
      if (this.QPPSDetail.includesSustainability) {
        feature.push('Sustainability Solutions');
      }
      return feature;
    },
  },
  methods: {
    getPackages(countryQPPS, key) {
      return countryQPPS[key].filter((word) => word.length > 0).sort((a, b) => a.localeCompare(b));
    },
    getPackagesNoOrder(countryQPPS, key) {
      return countryQPPS[key].filter((word) => word.length > 0);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    emitSWAEvent(QPPSCategory, QPPSName) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFCustomEvent', {
          eventName: 'Click_QPPS',
          custom2: QPPSCategory,
          custom3: QPPSName,
        });
      }
    },
  },
};
</script>

<template>
  <udex-modal
    id="modal"
    type="defalt"
    :open="true"
    tabindex="0"
    @keydown.esc="toggleModal(false)"
    block-layer-hidden
    on-desktop
    media-range="XL"
    automation-id="profileQPPSDetailModal"
  >
    <div class="ui-5-bar header" slot="header">
      <h3
        slot="startContent"
        v-html="$sanitize(QPPSDetail.packageName)"
        automation-id="profileQPPSDetailModal-heading"
      ></h3>
      <udex-control-button
        @click="toggleModal(false)"
        class="closeDialog"
        slot="endContent"
        icon="decline"
        icon-only
        has-icon
        accessible-name="close"
        automation-id="profileQPPSDetailModal-headerCloseButton"
      ></udex-control-button>
    </div>

    <div class="QPPS-img-container">
      <img
        v-if="VPUC"
        class="VPUC-img"
        src="../../assets/SAP_Validated_Partner_Use_Case_R.png"
        alt=""
        automation-id="profileQPPSDetailModal-VPUCimage"
      />
      <img
        v-if="!VPUC"
        alt=""
        class="QPPS-img"
        src="../../assets/SAP_Qualified_PartnerPackageSolution_R.png"
        automation-id="profileQPPSDetailModal-QPPSimage"
      />
    </div>
    <div
      class="profile-QPPS-text"
      v-html="$sanitize(QPPSDetail.teaserText)"
      automation-id="profileQPPSDetailModal-descriptionText"
    ></div>

    <ui5-table>
      <ui5-table-row v-if="getSolution.length > 0">
        <ui5-table-cell class="IE-tbl-layout vertical-align-cell">
          <span class="solution-competency-item-container" automation-id="profileQPPSDetailModal-packageCelltext">{{
            $t("QPPS.modal.package")
          }}</span>
        </ui5-table-cell>
        <ui5-table-cell class="IE-tbl-layout-list vertical-align-cell">
          <ul>
            <li
              class="profile-specialization-text"
              v-for="(product, idx) in getSolution"
              :key="product + '-' + idx"
              automation-id="profileQPPSDetailModal-packageText"
            >
              {{ product }}
            </li>
          </ul>
          <div
            v-if="moreSolution > 0 && !showAllSolution"
            class="QPPS-show-more"
            @click="showAllSolution = true"
            automation-id="profileQPPSDetailModal-showMoreSolution"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ moreSolution + " " + $tc("QPPS.modal.more.solution", moreSolution) }}
          </div>
        </ui5-table-cell>
      </ui5-table-row>
      <ui5-table-row v-if="getIndustry.length > 0">
        <ui5-table-cell class="IE-tbl-layout vertical-align-cell">
          <span class="solution-competency-item-container" automation-id="profileQPPSDetailModal-industryCellText">{{
            $t("QPPS.modal.industry")
          }}</span>
        </ui5-table-cell>
        <ui5-table-cell class="IE-tbl-layout-list vertical-align-cell">
          <ul>
            <li
              class="profile-specialization-text"
              v-for="(product, idx) in getIndustry"
              :key="product + '-' + idx"
              automation-id="profileQPPSDetailModal-industryText"
            >
              {{ product }}
            </li>
          </ul>
          <div
            v-if="moreIndustry > 0 && !showAllSolution"
            class="QPPS-show-more"
            @click="showAllSolution = true"
            automation-id="profileQPPSDetailModal-showMoreIndustry"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ moreIndustry + " " + $tc("QPPS.modal.more.industry", moreIndustry) }}
          </div>
        </ui5-table-cell>
      </ui5-table-row>
      <ui5-table-row v-if="getModalFeature.length > 0">
        <ui5-table-cell class="IE-tbl-layout vertical-align-cell">
          <span
            class="solution-competency-item-container"
            automation-id="profileQPPSDetailModal-specialFeatureCellText"
            >{{ $t("QPPS.modal.feature") }}</span
          >
        </ui5-table-cell>
        <ui5-table-cell class="IE-tbl-layout-list vertical-align-cell">
          <ul>
            <li
              class="profile-specialization-text"
              v-for="(product, idx) in getModalFeature"
              :key="product + '-' + idx"
              automation-id="profileQPPSDetailModal-specialFeatureText"
            >
              {{ product }}
            </li>
          </ul>
        </ui5-table-cell>
      </ui5-table-row>
      <ui5-table-column slot="columns-1">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
      <ui5-table-column slot="columns-2" min-width="500" popin-text="" demand-popin class="table-header-text-alignment">
        <span class="IE-tbl-header"></span>
      </ui5-table-column>
    </ui5-table>

    <ui5-bar class="footer" slot="footer" design="Footer" data-sap-ui-fastnavgroup="true">
      <udex-button
        v-if="showBackButton"
        @click="backButtonAction()"
        design="Emphasized"
        size="Large"
        slot="endContent"
        automation-id="profileQPPSDetailModal-backButton"
      >
        {{ $t("button.back") }}
      </udex-button>
      <!-- TODO: switch back to udex-button when the default behavior will be fixed -->
      <!-- https://github.tools.sap/sapudex/digital-design-system/issues/808 -->
      <udex-button
        v-if="
          (QPPSDetail.assetsLink?.trim().startsWith('https://') ||
            QPPSDetail.assetsLink?.trim().startsWith('http://')) &&
          $showVPUCFeature
        "
        @click.prevent="
          openInNewTab(QPPSDetail.assetsLink);
          emitSWAEvent(QPPSCategory, QPPSDetail.packageName);
        "
        design="Default"
        size="Large"
        slot="endContent"
        automation-id="profileQPPSDetailModal-moreInfoButton"
      >
        {{ $t("QPPS.modal.more.info") }}
      </udex-button>
      <udex-button
        @click="toggleModal(false)"
        design="Transparent"
        size="Large"
        class="hide-for-small-screen"
        slot="endContent"
        automation-id="profileQPPSDetailModal-footerCloseButton"
      >
        {{ $t("button.close") }}
      </udex-button>
    </ui5-bar>
  </udex-modal>
</template>

<style lang="less" scoped>
#modal h3 {
  &:extend(.HeadingMediumXS-size);
  color: #000000;
  max-width: 488px;
  margin-top: 0;
  margin-bottom: 0;
}
#modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--udexSpacer8);
}
#modal ui-5-bar::part(bar) {
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}
#modal div.ui-5-bar::part(bar) {
  margin-left: -12px;
  margin-right: -12px;
  width: auto;
}
#modal div.header::part(bar) {
  margin-right: -24px;
}
#modal .closeDialog {
  --udex-button-icon-l-size: 24px;
  --udex-button-color-tertiary-standard-default-text-and-icon: var(--udexColorGrey7, #475e75);
}
.profile-QPPS-text {
  &:extend(.body-s-size);
  color: #223548 !important;
}
.QPPS-show-more {
  width: fit-content;
  &:extend(.font-expanded);
  cursor: pointer;
  color: @color-link;
}
.QPPS-show-more:hover {
  color: @color-link__hover;
}
.VPUC-img {
  max-width: 177px;
}
.QPPS-img {
  width: 194.62px;
}
.QPPS-img-container {
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-bottom: 24px;
}
.count-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-specialization-text,
.QPPS-show-more {
  &:extend(.body-s-size);
  font-size: 16px;
  line-height: 24px;
  color: @color-grey-0;
  max-width: 350px;
  list-style-position: outside;
  margin-bottom: 8px;
}
.profile-specialization-text:last-child {
  margin-bottom: unset;
}
.IE-tbl-header {
  height: 0;
}
.IE-tbl-layout-list {
  text-align: left;
}
.solution-competency-item-container {
  &:extend(.body-s-size);
  color: #223548;
  padding-left: 0 !important;
}
.vertial-align-cell {
  vertical-align: top;
}
@media (max-width: 550px) {
  .solution-competency-item-container {
    &:extend(.body-s-size);
    color: #223548;
    margin-left: -10px;
  }
  .QPPS-img {
    width: 135px;
    height: 32px;
  }
  .VPUC-img {
    width: 123px;
    height: 32px;
  }
}
@media (min-width: 627px) {
  .IE-tbl-layout {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media (max-width: 626px) {
  .IE-tbl-layout {
    padding-top: 12px;
  }
  .IE-tbl-layout-list {
    padding-bottom: 4px;
  }
}
@media (max-width: 640px) {
  .hide-for-small-screen {
    display: none;
  }
}
@media (min-width: 680px) {
  .profile-QPPS-text {
    max-width: 544px;
    width: 544px;
  }
}
</style>
