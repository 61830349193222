<script>
import PFSpecializationTblComponent from './PFSpecializationTblComponent.vue';
import PFBaseModal from '@/components/common/modal/PFBaseModal.vue';
import PFCompetencyLevelImage from '@/components/common/modal/PFCompetencyLevelImage.vue';
import PFCompetencyLevelDescription from '@/components/common/modal/PFCompetencyLevelDescription.vue';
import ReadOnlyTag from '@/components/common/base/ReadOnlyTag.vue';
import { openInNewTab } from '@/utils/utils.js';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFCompetencyModalComponent',
  components: {
    PFSpecializationTblComponent,
    PFBaseModal,
    PFCompetencyLevelImage,
    PFCompetencyLevelDescription,
    ReadOnlyTag,
  },
  data: () => ({
    faqLinkCompetency: urlLinks.LEARN_MORE_SPECIALIZATION,
    faqLinkSolution: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
    tooltipOpen: {},
  }),
  props: {
    modalHeader: { type: String, default: '' },
    L1Key: { type: String, default: '' },
    competencyLevel: { type: String, default: '' },
    L2s: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Object,
      default: () => ({}),
    },
    industries: {
      type: Object,
      default: () => ({}),
    },
    toggleModal: { type: Function, default: () => { } },
    hasCompetency: { type: Boolean, default: () => false },
    specializations: { type: Array, default: () => [] },
  },
  computed: {
    shouldUseFullHeight() {
      return this.specializations.some((specialization) => !specialization.ERPMode);
    },
    hasCompetencyLevel() {
      return this.hasCompetency && this.competencyLevel?.toUpperCase() !== 'ZERO';
    },
    specializationTagText() {
      return this.$t('profile.competency.Specializations');
    },
  },
  methods: {
    openInNewTab,
    hasSpecialization() {
      return this.specializations.length > 0;
    },
    isNumOfSpecializationsEqNumSolutions() {
      return this.L2s.every((l2) => l2.hasSpecialization === true);
    },
    toggleTooltip(id) {
      this.tooltipOpen[id] = false;
    },
  },
};
</script>

<template>
  <PFBaseModal :modalHeaderText="modalHeader" :toggleModal :modalFullHeight="shouldUseFullHeight" automationId="PFCompetencyModalComponent">
    <PFCompetencyLevelImage v-if="hasCompetencyLevel" :competencyLevel />
    <div class="profile-competency-level-description">
      <PFCompetencyLevelDescription v-if="hasCompetencyLevel" :hasCompetency :competencyLevel :learnMoreLink="faqLinkSolution"/>
    </div>
    <div class="profile-competency-table">
      <table class="ds-table ds-m-none" ref="modalProfileCompetencyContentTbl">
        <tbody>
          <tr></tr>
          <tr v-if="!isNumOfSpecializationsEqNumSolutions()">
            <td class="td-border-none body-s-size" colspan="2">
              <div v-show="hasCompetency && competencyLevel.toUpperCase() !== 'ZERO'"
                class="ds-m-b-xs HeadingMediumXXXS-size PFSpecializationTblComponent-border">
                {{ $tc("profile.competency.Solutions", L2s.length) }}
              </div>
              <span v-show="hasCompetency && competencyLevel.toUpperCase() !== 'ZERO'">
                {{ $t("profile.competency.modal.solution.text") }}</span>
              <span v-show="!hasCompetency">{{ $t("profile.solution.text.competency.not.available") }}</span>
              <span v-show="hasCompetency && competencyLevel.toUpperCase() === 'ZERO'">
                {{ $t("profile.solution.text.level.zero") }}</span>
              <udex-link accessibleRole="Link" @click.prevent="openInNewTab(faqLinkSolution)">
                {{ $t("learn.more") }}
              </udex-link>
            </td>
          </tr>
          <template v-if="!isNumOfSpecializationsEqNumSolutions()">
            <ul class="solution-list">
              <li v-for="(solution, idx) in L2s" :key="solution.solutionL2Name + '-' + idx" class="solution-item">
                <div class="solution-item-container">
                  <div class="solution-name-column">
                    <div class="spe-name-data body-s-size">
                      <span class="solution-name-text">{{ solution.solutionL2Name }}</span>
                      <div v-if="L1Key !== 'PFFIN' && !solution.specializationAvailable" class="tool-tip-container"
                        v-click-outside="() => toggleTooltip(solution.solutionL2Name)">
                        <button @onClick="(e) => e.stopPropagation()"
                          class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs ds-p-none"
                          :class="tooltipOpen[solution.solutionL2Name] ? 'tool-tip-icon-clicked' : ''"
                          @click="tooltipOpen[solution.solutionL2Name] = !tooltipOpen[solution.solutionL2Name]"></button>
                        <div class="ds-tooltip-icon"
                          :class="solution.solutionL2Name.length > 30 ? 'toolitp-icon-item-list-ERP' : 'toolitp-icon-item-list'">
                          <div :class="[
                            tooltipOpen[solution.solutionL2Name] ? 'display-block' : 'display-none',
                            'tooltiptext',
                            tooltipOpen[solution.solutionL2Name] && solution.solutionL2Name.length > 30
                              ? 'ds-tooltip-icon__tooltip--ERP'
                              : 'ds-tooltip-icon__tooltip--service',
                          ]">
                            {{ $t("specialization.not.available.helptext") }}
                            <span @click="openInNewTab(faqLinkCompetency)" class="help-text-link">
                              <b> {{ $t("learn.more") }}</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tag-column">
                    <ReadOnlyTag v-if="solution.hasSpecialization" :tagText="specializationTagText" />
                  </div>
                </div>
              </li>
            </ul>
          </template>
          <tr>
            <td class="PFSpecializationTblComponent-td td-border-none body-s-size" colspan="2"
              v-if="hasSpecialization()">
              <div class="ds-m-b-xs  PFSpecializationTblComponent-border HeadingMediumXXXS-size">
                {{ $tc("profile.competency.Specializations", specializations.length) }}
              </div>
              {{ $t("profile.competency.modal.specialization.text") }}
              <udex-link accessibleRole="Link" @click.prevent="openInNewTab(faqLinkCompetency)">
                {{ $t("learn.more") }}
              </udex-link>
            </td>
          </tr>
          <tr>
            <td class="td-border-none spe-table" colspan="2">
              <div v-for="(spe, idx) in specializations" :key="idx + spe.solutionL2Key">
                <PFSpecializationTblComponent :specialization="spe"
                  :showTableInitial="specializations && specializations.length === 1" :idx="idx" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </PFBaseModal>
</template>

<style lang="less" scoped>
.solution-list {
  padding-left: 18px;
  margin: 0;
  container: solution-list / inline-size;
}
@container solution-list (max-width:500px){
  .solution-item-container{
    .tag-column{
      align-items: self-start;
    }
  }
}
.solution-item-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 15px; 
}

.solution-name-column {
  display: flex;
  align-items: center;  
}

.tag-column {
  display: flex;
  align-items: center;
}


.tool-tip-container {
  display: flex;
  align-items: center;
}

.tool-tip-icon {
  margin-left: 10px;
}

.help-text-link,
.help-text-link b {
  color: @white-color;
}


.spe-name-data {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 4px 0;
}

.tool-tip-container {

  button {
    background: none;
    border: none;
  }
}

.PFSpecializationTblComponent-td {
  padding-top: 20px;
}

.PFSpecializationTblComponent-border {
  border-top: 1px solid #eaeaea !important;
  padding-top: 16px;
}

.profile-competency-level-description {
  padding-top: 20px;
}

.spe-table {
  padding-top: 0px !important;
  padding-left: 2px !important;
}

.td-border-none {
  border: none !important;
  padding-left: 0px;
}

.ds-table {
  width: 100%;
}

@media (min-width: 640px) {
  .profile-competency-level-description {
    padding-top: 20px;
  }

  .PFSpecializationTblComponent-border {
    padding-top: 24px;
  }
}
@media (min-width: 600px) {
  .tag-column {
    margin-right: 96px;
  }
}
@media (min-width: 500px) {
  .tag-column {
    margin-right: 46px;
  }
}

</style>
