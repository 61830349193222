<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PFPartnerLogoComponent from '@/components/common/PFPartnerLogoComponent';

const store = useStore();
const { t } = useI18n({});

const partnerProfileData = computed(() => store.state.partnerProfile.profileData);
const contactPartnerTitle = computed(() => `${t('contact.me.modal.title')} ${partnerProfileData.value.name}`);
</script>
<template>
  <div class="header-container">
    <PFPartnerLogoComponent class="" :logo="partnerProfileData.logoUrl" :title="partnerProfileData.name"
      :isContactMe="true" />
    <div class="HeadingMediumM-size ">
      <div>{{ contactPartnerTitle }}</div>
      <div></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.header-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  gap: 12px;
}
</style>
