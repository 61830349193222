<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';
import '@sapudex/web-components/dist/FilterMenu.js';
import '@sapudex/web-components/dist/FilterMenuItem.js';
import '@sapudex/web-components/dist/FilterMenuGroupHeader.js';
import '@sapudex/web-components/dist/FilterMenuSelectAll.js';
import { getShowButtonText } from '@/utils/filterUtils.js';

export default {
  name: 'PFSolutionFilterComponent',
  props: {
    filterName: { type: String, default: '' },
  },
  data() {
    return {
      renderKey: 0,
    };
  },

  mounted() {
    this.sortSolution();
  },
  methods: {
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    ...mapActions('solutionL2Store', {
      unselectAllSolution: 'unselectAllSolution',
      removeSelectedL2Item: 'removeSelectedL2Item',
      addSelectedL2Item: 'addSelectedL2Item',
    }),
    onFilterReset() {
      this.unselectAllSolution(true);
      this.renderKey++;
    },
    onFilterCloseOutside() {
      this.renderKey++;
    },
    addOrRemoveL2Item(solution) {
      const solutionL2Status = this.solutionDataMap.get(solution.L1).L2Data.get(solution.L2).status;

      if (solutionL2Status === 1) {
        this.removeSelectedL2Item(solution);
      } else {
        this.addSelectedL2Item(solution);
      }
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
    getL1Title(L1Option) {
      let selectedItems = '';
      const solutionL1ItemStatus = this.solutionDataMap?.get(L1Option.title)?.status;

      if (solutionL1ItemStatus !== 0) {
        const count = L1Option.L2.filter((L2Item) => {
          const solutionL2ItemStatus = this.solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Item)?.status;
          return solutionL2ItemStatus === 1;
        }).length;
        selectedItems = ` (${count})`;
      }
      const L1Title = this.solutionDataMap.get(L1Option.title).title;
      return this.addSpaceAfterComma(L1Title) + selectedItems;
    },
    getL2Title(L2Option) {
      const L1Title = this.reverseL2L1Mapping.get(L2Option);
      return this.solutionDataMap.get(L1Title).L2Data.get(L2Option).title;
    },

    handleSelectionChange(event) {
      const selectedItem = [event.detail.item];
      const L1Item = selectedItem[0].parentNode.accessKey;
      const L2Item = selectedItem[0].accessKey;
      const solutionObject = { L1: L1Item, L2: L2Item };
      this.addOrRemoveL2Item(solutionObject);
      this.renderKey++;
    },
  },
  computed: {
    ...mapState('solutionL2Store', {
      updateSolutionFlag: (state) => state.updateSolutionFlag,
      sortedSolution: (state) => state.sortedSolution,
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
      solutionBadge: (state) => state.solutionBadge,
    }),

    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      isCompetency: (state) => state.selectedOptions.isCompetency,
      selectedOptions: (state) => state.selectedOptions.products,
      selectedDropdownOptions: (state) => [
        ...state.selectedOptions.industry,
        ...state.selectedOptions.engagement,
        ...state.selectedOptions.location,
        ...state.selectedOptions.designation,
        ...state.selectedOptions.isCompetency,
      ],

    }),
    isCloudCompetencySelected() {
      return this.isCompetency.length > 0;
    },
    isSolutionOptionSelected() {
      return [...this.solutionBadge.keys()]?.length > 0;
    },
    isAnyFilterSelected() {
      return this.isSolutionOptionSelected || this.isCloudCompetencySelected || this.selectedDropdownOptions.length > 0;
    },
    showButtonText() {
      return getShowButtonText(this.isAnyFilterSelected, this.countResults);
    },
    badgeCounter() {
      return this.selectedOptions.length;
    },
  },
  watch: {
    updateSolutionFlag(val, oldVal) {
      if (oldVal && !val) {
        this.sortSolution();
      }
    },
  },
};
</script>

<template>
<div class="preview-filtering flex items-center">
<udex-filter-menu
      :key="renderKey"
      id="filter-menu"
      mode="MultiSelect"
      height="290px"
      :search-placeholder="$t('filter.search.input.placeholder')"
      :filter-name="filterName"
      :result-btn-label="showButtonText"
      desktop
      @after-close="onFilterCloseOutside"
      @selection-change="handleSelectionChange"
      @reset-click="onFilterReset"
      disable-target-overlap="true"
      :badgeValue.prop="badgeCounter"

>
<udex-filter-menu-group-header
        v-for="L1Option in sortedSolution"
        ref="headerItem"
        :key="L1Option.title"
        :accessKey="L1Option.title"
        :label="getL1Title(L1Option)"
        v-show="L1Option.display"
        mode="MultiSelect"
        controlled
        nonInteractive
>
<udex-filter-menu-item
          v-for="L2Option in L1Option.L2"
          ref="selecteItem"
          :key="L2Option"
          :accessKey="L2Option"
          :label="getL2Title(L2Option)"
          mode="MultiSelect"
          controlled
          :selected.prop="Boolean(solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.status)"
          :disabled.prop="!solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available"
></udex-filter-menu-item>
</udex-filter-menu-group-header>
</udex-filter-menu>
</div>
</template>

<style lang="less">
udex-filter-menu {
  --udex-filter-menu-popover-max-width: 360px;
}
</style>
