<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PFBaseModalHeader from '@/components/common/modal/PFBaseModalHeader';
import PFContactEmailFormContent from '@/components/common/PFContactEmailFormContent';
import PFContactEmailHeader from '@/components/common/PFContactEmailHeader';
import PFContactEmailSubmitStatus from '@/components/common/PFContactEmailSubmitStatus';
import '@sapudex/web-components/dist/Modal.js';
import '@sapudex/web-components/dist/Button.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';

const store = useStore();
const { t } = useI18n();

const contactModalOpen = ref(false);
const resetCheckbox = ref(false);
const isContactEmailChecked = ref(false);

const requestStatus = computed(() => store.state.contactEmail.requestStatus);

const onSubmitButtonText = computed(() => (requestStatus.value === 1 ? t('contact.me.send.request') : t('button.close')));
const onSubmitButtonDisabled = computed(() => (requestStatus.value === 1 ? true : null));

function beforeCloseModal(event) {
  if (event.detail.escPressed) {
    event.preventDefault();
  }
}

function toggleContactModal() {
  contactModalOpen.value = !contactModalOpen.value;
}

function closeModal() {
  if (isContactEmailChecked.value) {
    resetCheckbox.value = true;
  }
  contactModalOpen.value = false;
  isContactEmailChecked.value = false;
  store.dispatch('contactEmail/updateRequestStatus', null);
}
</script>

<template>
  <div class="no-outline" tabindex="0" automation-id="contactEmailForm-container">
    <button
      class="ds-button ds-button--primary contact-email-button"
      type="button"
      @click="
        toggleContactModal();
        $eventBus.$emit('PFAACustomEvent', { eventName: 'ContactFormView' });
      "
      automation-id="contactEmailForm-contactEmailButton"
    >
      {{ $t("email.contact") }}
    </button>

<Teleport to="body">
      <udex-modal
        id="modal"
        :open="true"
        v-if="contactModalOpen"
        stretch
        accessibleRole="Dialog"
        accessibleName="Contact form"
        @before-close="beforeCloseModal"
        @close="closeModal"
        automation-id="contactEmailForm-modal"
      >
        <PFBaseModalHeader modalHeaderText="Contact form" :closeModal automation-id="contactEmailForm-modalHeader" />

        <div class="modal-content" v-if="!requestStatus" automation-id="contactEmailForm-modalContent">
          <div class="modal-form-header" automation-id="contactEmailForm-modalFormHeader">
            <PFContactEmailHeader automation-id="contactEmailForm-contactEmailHeader" />
          </div>
          <PFContactEmailFormContent automation-id="contactEmailForm-contactEmailFormContent" />
        </div>
        <!-- Submit spinner and success message with image -->
        <PFContactEmailSubmitStatus automation-id="contactEmailForm-contactEmailSubmitStatus" />
        <ui5-bar
          v-if="requestStatus"
          class="footer"
          slot="footer"
          design="Footer"
          data-sap-ui-fastnavgroup="true"
          automation-id="contactEmailForm-footer"
        >
          <udex-button
            @click="closeModal"
            design="Emphasized"
            size="Large"
            slot="startContent"
            :disabled="onSubmitButtonDisabled"
            accessible-name="Clsoe contact e-mail button"
            automation-id="contactEmailForm-closeButton"
          >
            {{ onSubmitButtonText }}
          </udex-button>
        </ui5-bar>
      </udex-modal>
</Teleport>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.modal-form-header {
  margin-bottom: 32px;
}

#modal {
  max-width: 955px;
  max-height: 1108px;
  --udexModalAndOverlayModalContentPaddingHorizontal: 56px;
  --udexModalAndOverlayModalContentPaddingVertical: 56px;
  z-index: 300;
}

.contact-email-button {
  &:extend(.font-normal);
  width: 160px;
  max-width: none;
  font-size: 16px;
  text-align: center;
}

.no-outline {
  outline: 0;
}

@media (max-width: 640px) {
  #modal {
    max-width: 955px;
    --udexModalAndOverlayModalContentPaddingHorizontal: 24px;
  }
}
</style>
