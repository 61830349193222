<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import '@ui5/webcomponents/dist/BusyIndicator.js';

const store = useStore();
const { t } = useI18n();

const requestStatus = computed(() => store.state.contactEmail.requestStatus);
const partnerProfileData = computed(() => store.state.partnerProfile.profileData);
</script>

<template>
  <div class="submit-indicator" v-if="requestStatus" automation-id="contactEmailForm-submitIndicator">
    <div class="image-container" automation-id="contactEmailForm-imageContainer">
      <img
        v-if="requestStatus === 2"
        src="@/assets/Checklist.svg"
        alt="Checklist icon"
        automation-id="contactEmailForm-checklistIcon"
      />
      <ui5-busy-indicator v-else size="Large" active :delay="0" automation-id="contactEmailForm-busyIndicator" />
    </div>
    <div v-if="requestStatus === 2" class="HeadingMediumS-size" automation-id="contactEmailForm-successMessage">
      {{ t("contact.me.send.success") }}
    </div>
    <div v-else class="HeadingMediumS-size" automation-id="contactEmailForm-sendingMessage">
      {{ t("contact.me.sending") }}
    </div>
    <div v-if="requestStatus === 2" class="body-s-size" automation-id="contactEmailForm-successSubtitle">
      {{
        t("contact.me.send.success.subtitle.one") + partnerProfileData.name + t("contact.me.send.success.subtitle.two")
      }}
    </div>
    <div v-else class="body-s-size" automation-id="contactEmailForm-sendingSubtitle">
      {{ t("contact.me.sending.subtitle") }}
    </div>
  </div>
</template>

<style scoped>
.submit-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 500px;
  height: 100%;
  text-align: center;
  margin: auto;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
