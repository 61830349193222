<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import SendEmail from '@/plugins/sendEmail';

import '@sapudex/web-components/dist/MessageStrip.js';

const store = useStore();
const { t } = useI18n();
const route = useRoute();

const partnerProfileData = computed(() => store.state.partnerProfile.profileData);
const profileAuthorizations = computed(() => store.state.userProfile.user.profileAuthorizations);

function sendEmailBackendError() {
  const subject = encodeURIComponent('help.mail.body.recaptcha.error.subject');
  const profileId = partnerProfileData.value.profileId ? partnerProfileData.value.profileId : 'N/A';
  const userId = profileAuthorizations.value?.userName ? profileAuthorizations.value.userName : 'N/A';
  const pageTitle = route.name;
  const url = window.location.href;
  SendEmail.sendEmailBackendError(subject, profileId, userId, pageTitle, url);
}
</script>

<template>
  <udex-message-strip design="Negative" status-type="Icon" :hide-close-button="true">
    {{ t("contact.me.send.fail") }} {{ t("editor.footer.message.contact") }}
    <udex-link class="email-send-fail-contact" href="" @click.prevent="sendEmailBackendError()">
      {{ t("editor.footer.message.contact.email") }}
    </udex-link>
    {{ t("editor.footer.message.contact.b") }}
  </udex-message-strip>
</template>
